import React from 'react';
import {RequestHistoryHeader} from "../Components/RequestHistoryHeader";
import RequestTable from "../Components/RequestTable";

export default function Dashboard() {
    return (
        <>
            <RequestHistoryHeader/>
            <RequestTable/>
        </>
    );
}
