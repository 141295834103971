import TextInput from "./TextInput";
import Button from "./Button";
import * as React from "react";
import {ChangeEvent, useEffect, useState} from "react";
import {ICreateForm, IJsonRequest, IProcess, IResponseCreate} from "../Models/Models";
import {ALERT, API_URL, RELOAD} from "../Config/Config";
import {useStateApp} from "../Context/AppContext";
import {Skeleton} from "@mui/material";
import {apiClient} from "../Config/Api";

export function RequestCreateForm({closeModal, parsers}: ICreateForm) {
    const style: object = {
        display: 'flex',
        justifyContent: 'space-between',
    }

    const [formData, setFormData] = useState({
        name: '',
        link: '',
        parser_id: 0,
    });
    const {state, dispatch} = useStateApp();
    const initProcess: IProcess = {
        requestId: 0,
        isStop: false,
        isComplete: false,
        status: 'В работе',
        searchCount: 0,
        completeCount: 0,
    };
    const [process, setProcess] = useState(initProcess);
    const [requestId, setRequestId] = useState(0);
    const [disabledStop, setDisabledStop] = useState(false);

    useEffect(() => {
        setFormData({
            ...formData,
            parser_id: state?.filter?.parserId ?? 0
        });
    }, [state?.filter?.parserId]);

    useEffect(() => {
        if (state?.process && requestId == state?.process.requestId) {
            setProcess(state?.process);
        }
    }, [state?.process]);

    const submitForm = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await apiClient.put<IResponseCreate>("requests", formData).then((response) => {
                if (response.data.status === 'ok' && response.data.requestId) {
                    setRequestId(response.data.requestId);
                    dispatch && dispatch({type: RELOAD});
                } else {
                    throw new Error(response.data.error);
                }
            });
        } catch (error) {
            dispatch && dispatch({
                type: ALERT, alert: {
                    isView: true,
                    isError: true,
                    text: error?.toString() ?? 'Не удалось спарсить информацию',
                }
            });
            console.error(error);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value,
            parser_id: state?.filter?.parserId ?? 0
        });
    };

    const stopProcessHendler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setDisabledStop(true);
        try {
            await apiClient.get<IJsonRequest>("requests/stop_process/" + requestId).then((response) => {
                if (response.data.status === 'ok') {
                    setProcess({
                        ...process,
                        isStop: true,
                    });
                } else {
                    throw new Error(response.data.error);
                }
            });
        } catch (error) {
            setDisabledStop(false);
            dispatch && dispatch({
                type: ALERT, alert: {
                    isView: true,
                    isError: true,
                    text: error?.toString() ?? 'Не удалось прервать процесс',
                }
            });
            console.error(error);
        }
    }

    const downloadHendler = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (requestId) {
            document.location.href = API_URL + 'download/' + requestId;
        }
    }

    const continueHendler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        try {
            await apiClient.get<IJsonRequest>("requests/continue_process/" + requestId).then((response) => {
                if (response.data.status === 'ok') {
                    setProcess({
                        ...process,
                        isStop: false,
                    });
                    setDisabledStop(false);
                } else {
                    throw new Error(response.data.error);
                }
            });
        } catch (error) {
            dispatch && dispatch({
                type: ALERT, alert: {
                    isView: true,
                    isError: true,
                    text: error?.toString() ?? 'Не удалось продолжить процесс',
                }
            });
            console.error(error);
        }
    }

    return (
        <form
            onSubmit={submitForm}
        >
            <TextInput
                name="name"
                placeholder={'Название товара*'}
                value={formData.name}
                onChange={handleChange}
                required={true}
            />
            <TextInput
                name="link"
                placeholder={'Ссылка*'}
                value={formData.link}
                onChange={handleChange}
                required={true}
            />
            {!requestId ? (
                <Button>
                    Начать парсить
                </Button>
            ) : (
                <Button
                    className={'process'}
                    disabled={process?.isComplete || !process?.searchCount || (process?.completeCount + 2 >= process?.searchCount) || disabledStop}
                    onClick={stopProcessHendler}
                >
                    Прервать
                </Button>
            )}
            {requestId > 0 && <>
                <hr/>
                <div style={{...style}}>
                    <div>Найдено:</div>
                    <div>{process?.searchCount ? process?.searchCount : <Skeleton width={'25px'}/>}</div>
                </div>
                <div style={{...style}}>
                    <div>Спарсили:</div>
                    <div>{process?.completeCount}</div>
                </div>
                <div style={{...style}}>
                    <div>Статус:</div>
                    <div style={process?.isComplete && !process?.error ? {
                        color: 'green'
                    } : (process?.error ? {
                        color: 'red'
                    } : {})}>{process?.status}</div>
                </div>
                {process?.isComplete && <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        marginTop: '10px',
                    }}
                >
                    {process?.isStop &&
                        <Button
                            className={'continue'}
                            onClick={continueHendler}
                        >
                            Продолжить
                        </Button>}
                    <Button
                        className={'download'}
                        onClick={downloadHendler}
                    >
                        Скачать
                    </Button>
                </div>}
            </>}
        </form>
    );
}
