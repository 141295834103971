import React, {Dispatch, useContext, useReducer} from "react";
import {IReducerAction, IRequestsFilter, IState} from "../Models/Models";
import {
    RELOAD,
    HIDE_LOADER,
    SHOW_LOADER,
    LOADED,
    FILTER,
    ALERT,
    FAILED,
    CHANGE_PARSER,
    PROCESS
} from "../Config/Config";

type DashboardContextType = {
    state?: IState,
    dispatch?: Dispatch<IReducerAction>,
}

const AppContext = React.createContext<DashboardContextType>({});

export const useStateApp = () => {
    return useContext(AppContext);
}

function reducer(state: IState, action: IReducerAction) {
    switch (action.type) {
        case SHOW_LOADER:
            return {...state, loaderShow: true}
        case HIDE_LOADER:
            return {...state, loaderShow: false}
        case RELOAD:
            return {...state, reload: true, loaderShow: true}
        case LOADED:
            return {...state, reload: false, loaderShow: false}
        case FILTER:
            return {...state, reload: true, filter: action.filter, loaderShow: true}
        case ALERT:
            return {...state, alert: action.alert}
        case FAILED:
            return {...state, reload: false, loaderShow: false, alert: action.alert}
        case CHANGE_PARSER:
            return {...state, reload: true, loaderShow: true, filter: action.filter}
        case PROCESS:
            return {...state, process: action.process}
        default:
            return state
    }
}

export function AppProvider({children}: any) {
    const filter: IRequestsFilter = {
        page: 0,
        limit: 10,
    };
    const [state, dispatch] = useReducer(reducer, {
        loaderShow: true,
        reload: false,
        filter: filter,
    });

    return (
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    );
}