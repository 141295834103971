import React, { forwardRef, ForwardedRef, InputHTMLAttributes } from 'react';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
    type?: string;
    className?: string;
    isFocused?: boolean;
};

const TextInput = forwardRef(({
                                  type = 'text',
                                  className = '',
                                  isFocused = false,
                                  ...props
                              }: TextInputProps, ref: ForwardedRef<HTMLInputElement>) => {

    return (
        <input
            {...props}
            type={type}
            className={className}
        />
    );
});

export default TextInput;
