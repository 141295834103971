import {IStatus} from "../Models/Models";
import {Tooltip} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import * as React from "react";

export function Status({status, className = '', tooltip = ''}: IStatus) {
    return (
        <span className={`status
            ${status === 'Выполнено' && 'success'}
            ${(status === 'В процессе' || status === 'Прервано') && 'processed'}
            ${status === 'Ошибка' && 'error'} ` + className}>
            {status}
            {tooltip && (
                <Tooltip title={tooltip} arrow><HelpOutlineIcon/></Tooltip>
            )}
        </span>
    );
}