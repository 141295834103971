// Site URLs
const host = window.location.host.split(':');
const isLocal= host[0] === 'localhost';
const protocol = (isLocal ? 'http' : 'https');
const protocolSocket = (isLocal ? 'ws' : 'wss');
const port = (isLocal ? '3500' : '3501');
export const BACKEND_URL: string = `${protocol}://${host[0]}:${port}`;
export const SOCKET_URL: string = `${protocolSocket}://${host[0]}` + ((isLocal) ? ':3502' : '/websocket/');

export const API_URL: string = BACKEND_URL + '/api/';

// State app constants
export const SHOW_LOADER = 'show_loader';
export const HIDE_LOADER = 'hide_loader';
export const RELOAD = 'reload';
export const LOADED = 'loaded';
export const FAILED = 'failed';
export const FILTER = 'filter';
export const PROCESS = 'process';

export const ALERT = 'alert';

export const CHANGE_PARSER = 'change_parser'