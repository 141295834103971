import React from "react";
import {IButton} from "../Models/Models";

export default function Button({ className = '', disabled, children, ...props }: IButton) {
    return (
        <button
            {...props}
            className={
                `r-btn ${
                    disabled && 'disabled'
                } ` + className
            }
            disabled={disabled}
        >
            {children}
        </button>
    );
}
