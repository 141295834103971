import {MenuItem, Modal, Select, SelectChangeEvent, Skeleton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "./Button";
import * as React from "react";
import SearchField from "./SearchField";
import {RequestCreateForm} from "./RequestCreateForm";
import {useEffect, useState} from "react";
import {useStateApp} from "../Context/AppContext";
import {IJsonRequest, IJsonRequestParsers} from "../Models/Models";
import {ALERT, CHANGE_PARSER} from "../Config/Config";
import {apiClient} from "../Config/Api";

export function RequestHistoryHeader({...props}) {
    const [open, setOpen] = useState(false);
    const parsersInit: IJsonRequestParsers[] = [];
    const [parsers, setParsers] = useState(parsersInit);
    const [currentParser, setCurrentParser] = useState('');
    const {state, dispatch} = useStateApp();

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentParser(event.target.value as string);
        if (dispatch) {
            dispatch({
                type: CHANGE_PARSER,
                filter: {
                    ...state?.filter,
                    parserId: parseInt(event.target.value)
                }
            });
        }
    }

    const getParsers = async () => {
        try {
            await apiClient.get<IJsonRequest<IJsonRequestParsers[]>>('requests/parsers/')
                .then((response) => {
                    if (dispatch
                        && response?.data?.status === 'ok'
                        && response?.data.data
                    ) {
                        if (response.data.data.length) {
                            setParsers(response.data.data);
                            dispatch({
                                type: CHANGE_PARSER,
                                filter: {
                                    ...state?.filter,
                                    parserId: response.data.data[0].id
                                }
                            });
                            setCurrentParser(response.data.data[0].id.toString());
                        }
                    } else {
                        throw new Error(response?.data?.error);
                    }
                });
        } catch (error) {
            dispatch && dispatch({type: ALERT, alert: {
                isView: false,
                isError: true,
                text: error instanceof Error ? error.message : 'Не удалось получить список парсеров',
            }});
        }
    }

    useEffect(() => {
        getParsers();
    }, []);

    return (
        <div>
            <div className="parser-header">
                <h1>Парсинг дилеров по товару</h1>
                {parsers?.length > 0 ? <Select
                    className={'parser-select'}
                    value={currentParser}
                    onChange={handleChange}
                >
                    {parsers.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                </Select> : <Skeleton style={{
                    width: '100%',
                    maxWidth: '378px',
                    height: '80px'
                }} />}
            </div>
            <div className="parser-header">
                <SearchField
                    placeholder="Введите название товара"
                />
                <Button
                    children={'Начать парсить'}
                    onClick={handleOpen}
                />
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <div className="popup">
                        <div className="popup-header">
                            Начать парсить
                            <CloseIcon onClick={handleClose}/>
                        </div>
                        <div className="popup-content">
                            <RequestCreateForm closeModal={handleClose} parsers={parsers}/>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
}
