import {Skeleton} from "@mui/material";
import * as React from "react";
import {INoItemAndLoading} from "../Models/Models";
import {useStateApp} from "../Context/AppContext";

export function NoItemAndLoading({ lastCountRow }: INoItemAndLoading) {
    const emptyRowArray = new Array(lastCountRow > 0 ? lastCountRow : 1).fill(null);
    const emptyColumnArray = new Array(6).fill(null);
    const {state} = useStateApp();

    return (!state || !state.loaderShow ?
            (<div
                className="row no-items"
            >
                Нет объектов
            </div>) : (
                <>
                    {emptyRowArray.map((_, i) =>
                        <div
                            className="row skeleton"
                            key={i}
                        >
                            {emptyColumnArray.map((_, i) =>
                                <div
                                    className="column"
                                    style={{
                                        padding: '6px',
                                    }}
                                    key={i * i}
                                >
                                    <Skeleton style={{
                                        minWidth: '100%',
                                        width: (i === 5 ? '180px' : '100%')
                                    }}/>
                                </div>)}
                        </div>
                    )}
                </>
            )
    );
}