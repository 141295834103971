import React, {useEffect} from 'react';
import GeneralLayout from "./Layouts/GeneralLayout";
import Dashboard from "./Pages/Dashboard";
import {ALERT, PROCESS, RELOAD, SOCKET_URL} from "./Config/Config";
import {useStateApp} from "./Context/AppContext";
import {IMessageSocket, IProcess, IReducerAction} from './Models/Models';
import {setAuthToken} from "./Config/Auth";
import Login from "./Components/Login";
import {Alert, Snackbar} from "@mui/material";

let interval: string | number | NodeJS.Timeout | undefined;

function connectSocket(dispatch: React.Dispatch<IReducerAction> | undefined) {
    clearInterval(interval);
    interval = setInterval(function() {
        const processSocket = new WebSocket(SOCKET_URL);
        if (processSocket) {
            processSocket.onmessage = function (event) {
                try {
                    if (dispatch) {
                        const message: IMessageSocket = JSON.parse(event.data.toString());
                        switch(message.type) {
                            case 'reload':
                                dispatch({type: RELOAD});
                                break;
                            case 'process':
                                const process:IProcess = message.data;
                                dispatch({type: PROCESS, process: process});
                                break;
                            default:
                                console.log(event.data);
                        }
                    }
                } catch (error) {
                    console.log(event.data);
                }
            }
            processSocket.onclose = function (event) {
                connectSocket(dispatch);
            };
            processSocket.onopen = function() {
                clearInterval(interval);
            }
        }
    }, 3000);
}

const token = localStorage.getItem('authToken')

if (token) {
    setAuthToken(token);
}

function App() {
    const {state, dispatch} = useStateApp();

    useEffect(() => {
        connectSocket(dispatch);
    }, []);

    const viewAuthError = localStorage.getItem('errorAuth');

    useEffect(() => {
        if (viewAuthError == '1' && dispatch) {
            setTimeout(function(){
                localStorage.removeItem('errorAuth');
            }, 100);
            dispatch({
                type: ALERT,
                alert: {
                    text: 'Не удалось авторизоваться',
                    isError: true,
                    isView: true,
                }
            });
        }
    }, [viewAuthError, dispatch]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        if (state?.alert && dispatch) {
            dispatch({
                type: ALERT,
                alert: {
                    isView: false,
                    text: '',
                }
            });
        }
    };

    return (
        <GeneralLayout>
            {token ?
            <Dashboard/>
                :
            <Login />
            }
            {state?.alert?.isView && <Snackbar
                open={true}
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert
                    onClose={handleClose}
                    severity={state?.alert?.isError ? 'error' : 'success'}
                >
                    {state?.alert?.text}
                </Alert>
            </Snackbar>}
        </GeneralLayout>
    );
}

export default App;