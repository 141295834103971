import * as React from "react";
import {IJsonRequest, IRequest, IRequestTableRows} from "../Models/Models";
import { Status } from "./Status";
import {ALERT, API_URL, RELOAD, SHOW_LOADER} from "../Config/Config";
import ActionButton from "./ActionButton";
import { NoItemAndLoading } from "./NoItemAndLoading";
import {useStateApp} from "../Context/AppContext";
import DownloadIcon from '@mui/icons-material/Download';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import {apiClient} from "../Config/Api";

export function RequestTableRows({dataList, setDataList, lastCountRow, ...props}: IRequestTableRows) {
    const {state, dispatch} = useStateApp();

    const clickDeleteHandler = async (requestId: number) => {
        try {
            dispatch && dispatch({type: SHOW_LOADER});
            await apiClient.delete<IJsonRequest>('requests/' + requestId)
                .then((response) => {
                    dispatch && dispatch({type: RELOAD});
                    if (dispatch && response?.data?.status !== 'ok') {
                        throw new Error(response?.data?.error ?? 'Не удалось удалить запрос');
                    }
                });
        } catch (error) {
            dispatch && dispatch({
                type: ALERT,
                alert: {
                    text: error ? error.toString() : 'Произошла ошибка при отправке запроса',
                    isError: true,
                    isView: true,
                }
            });
            console.error(error);
        }
    }

    const clickReplyHandler = async (requestId: number) => {
        try {
            dispatch && dispatch({type: SHOW_LOADER});
            await apiClient.get<IJsonRequest>( 'requests/replay/' + requestId)
                .then((response) => {
                    dispatch && dispatch({type: RELOAD});
                    if (response?.data?.status !== 'ok') {
                        throw new Error(response?.data?.error ?? 'Не удалось повторить запрос');
                    }
                });
        } catch (error) {
            dispatch && dispatch({
                type: ALERT,
                alert: {
                    text: error ? error.toString() : 'Произошла ошибка при отправке запроса',
                    isError: true,
                    isView: true,
                }
            });
            console.error(error);
        }
    }

    const ConvertDate = (date: string | undefined): string => {
        if (date) {
            const today: Date = new Date(date);
            const thisMonth = today.getMonth() + 1;
            return today.getDate() + "." + (thisMonth < 10 ? '0' : '') + thisMonth + "." + today.getFullYear();
        }
        return '-';
    }

    return (
        <>
            {dataList.length && !state?.loaderShow ? dataList.map((item: IRequest) =>
                <div
                    {...props}
                    key={item.id}
                    className="row"
                >
                    <div className="column">{item.id}</div>
                    <div className="column">{item.name}</div>
                    <div className="column">{item.link_product}</div>
                    <div className="column">{ConvertDate(item.created_at)}</div>
                    <div className="column">
                        <Status
                            status={item.status}
                            tooltip={item.err_text}
                        />
                    </div>
                    <div className="column btn-groups">
                        <a
                            href={API_URL + 'download/' + item.id}
                        >
                            <ActionButton
                                className="green"
                                icon={<DownloadIcon/>}
                            />
                        </a>
                        <ActionButton
                            className="yellow"
                            icon={<ReplayIcon/>}
                            onClick={() => {
                                clickReplyHandler(item.id)
                            }}
                        />
                        <ActionButton
                            className="red"
                            icon={<DeleteIcon/>}
                            onClick={() => {
                                clickDeleteHandler(item.id);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <NoItemAndLoading
                    lastCountRow={lastCountRow}
                />
            )}
        </>
    );
}