import * as React from "react";
import {TablePagination} from "@mui/material";
import {IRequest, IJsonRequest, IJsonRequestPaginate} from "../Models/Models"
import {useEffect, useState} from "react";
import {useStateApp} from "../Context/AppContext";
import {FAILED, FILTER, HIDE_LOADER, LOADED} from "../Config/Config";
import { RequestTableRows } from "./RequestTableRows";
import {apiClient} from "../Config/Api";

export default function RequestTable({...props}) {
    const defaultRequestsList: IRequest[] = [];
    const [dataList, setDataList] = useState(defaultRequestsList);
    const {state, dispatch} = useStateApp();
    const [lastCountRow, setLastCountRow] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    const getRequests = async () => {
        if (state?.filter?.parserId) {
            try {
                let queryParams = '';
                if (state?.filter) {
                    let filterQuery = {
                        query: '',
                        page: '0',
                        limit: '10',
                        parser_id: '0',
                    };
                    if (state.filter.query !== undefined) {
                        filterQuery.query = state.filter.query;
                    }
                    if (state.filter.page !== undefined) {
                        filterQuery.page = state.filter.page.toString();
                    }
                    if (state.filter.limit !== undefined) {
                        filterQuery.limit = state.filter.limit.toString();
                    }
                    if (state.filter.limit !== undefined) {
                        filterQuery.parser_id = state.filter.parserId.toString();
                    }
                    queryParams = new URLSearchParams(filterQuery).toString();
                }
                return await apiClient.get<IJsonRequest<IJsonRequestPaginate>>('requests/?' + queryParams);
            } catch (error) {
                setDataList([]);
                dispatch && dispatch({
                    type: FAILED,
                    alert: {
                        text: error ? error.toString() : 'Произошла ошибка при загрузке',
                        isError: true,
                        isView: true,
                    }
                });
                console.error(error);
            } finally {
                dispatch && dispatch({type: HIDE_LOADER});
            }
        }
    }

    useEffect(() => {
        if (state?.loaderShow) {
            setDataList([]);
        }
        if (state?.reload === true) {
            if (dataList.length) {
                setDataList([]);
            }
            getRequests().then(request => {
                if (request?.data?.data?.requests) {
                    setDataList(request.data.data.requests);
                    setLastCountRow(request.data.data.requests.length);
                    if (request.data.data.total_count) {
                        setTotalCount(request.data.data.total_count);
                    }
                    dispatch && dispatch({type: LOADED});
                } else {
                    console.log('Empty request');
                }
            });
        }
    }, [state?.reload, state?.loaderShow]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        dispatch && dispatch({
            type: FILTER, filter: {
                ...state?.filter,
                page: newPage
            }
        });
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        dispatch && dispatch({
            type: FILTER, filter: {
                ...state?.filter,
                page: 0,
                limit: parseInt(event.target.value, 10),
            }
        });
    };

    return (
        <>
            <div className="request-table">
                <div className="scroll-box">
                    <div className="header">
                        <div className="column">№</div>
                        <div className="column">Название товара</div>
                        <div className="column">Ссылка на товар</div>
                        <div className="column">Дата</div>
                        <div className="column">Статус</div>
                        <div className="column">
                            Действия
                            <span>скачать/повторить/удалить</span>
                        </div>
                    </div>
                    <div className="body">
                        <RequestTableRows
                            dataList = {dataList}
                            setDataList = {setDataList}
                            lastCountRow = {lastCountRow}
                        />
                    </div>
                </div>
            </div>
            {totalCount > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={state?.filter?.page ? state.filter.page : 0}
                    onPageChange={handleChangePage}
                    rowsPerPage={state?.filter?.limit ? state.filter.limit : 10}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Показывать по'}
                    labelDisplayedRows={(paginationInfo) => {
                        return `${paginationInfo.from} из ${paginationInfo.count}`
                    }}
                />
            }
        </>
    );
}
