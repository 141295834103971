import * as React from "react";
import SearchIcon from '@mui/icons-material/Search';
import {useState} from "react";
import {ISearchField} from "../Models/Models";
import {useStateApp} from "../Context/AppContext";
import {FILTER} from "../Config/Config";

export default function SearchField({placeholder = '', className = '', onChange, ...props}: ISearchField) {
    const [value, setValue] = useState('');
    const {state, dispatch} = useStateApp();

    function handleChange(e: { target: { value: any; }; }) {
        const value = e.target.value;
        setValue(value);
        if (dispatch) {
            dispatch({
                type: FILTER,
                filter: {
                    ...state?.filter,
                    query: value,
                }
            });
        }
    }

    return (
        <div className="search-field">
            <input
                {...props}
                className={className}
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
            />
            <SearchIcon onClick={onChange} />
        </div>
    );
}
