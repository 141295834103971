import axios from "axios";
import {API_URL} from "./Config";

export const apiClient = axios.create({
    baseURL: API_URL,
});

apiClient.interceptors.response.use(
    response => response,
    error => {
        const { response } = error;
        if (response && response.status === 401) {
            localStorage.removeItem('authToken');
            localStorage.setItem('errorAuth', '1');
            document.location.reload();
        }
        return Promise.reject(error);
    }
);