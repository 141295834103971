import {IActionButtons} from "../Models/Models";
import * as React from "react";

export default function ActionButton ({icon, className = '', ...props}: IActionButtons) {
    return (
        <button
            {...props}
            className={'action-btn ' + className}
        >
            {icon}
        </button>
    );
}