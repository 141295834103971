import {apiClient} from "./Api";
import {ALERT} from "./Config";
import {IReducerAction} from "../Models/Models";
import React from "react";

export function login(email:string, password:string, dispatch: React.Dispatch<IReducerAction>|undefined) {
    apiClient.post('/login', { email, password })
        .then(response => {
            const token = response.data.token;
            localStorage.setItem('authToken', token);
            document.location.reload();
        })
        .catch(error => {
            dispatch && dispatch({
                type: ALERT,
                alert: {
                    text: error.response.data.message ?? 'Произошла ошибка при отправке запроса',
                    isError: true,
                    isView: true,
                }
            });
        });
}

export const setAuthToken = (token: string | undefined) => {
    if (token) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete apiClient.defaults.headers.common['Authorization'];
    }
};